<template>
  <div class="container">


    <div class="teacher-evaluation mt-4 mb-4">
      <h4 class="text-center mb-4">АНКЕТА «ПРЕПОДАВАТЕЛЬ ГЛАЗАМИ СТУДЕНТОВ»</h4>


      <div class="card mt-4">
        <div class="card-body">

          <div class="card-title mb-4">
            <div class="row">
              <div class="col-12 ml-3">
                <h2 class="d-block"
                    style="font-size: 1.3rem; font-weight: bold;">Информация о курсе</h2>
              </div>
            </div>
          </div>

          <div>
            <p><strong>Название курса:</strong> {{ teacherEvaluation_form.courseInfos?.course_name }}</p>
<!--            <div v-if="teacherEvaluation_form.courseInfos?.lecturePpsData?.id === teacherEvaluation_form.courseInfos?.seminarPpsData?.id">-->
<!--              <p><strong>Лектор:</strong> {{ teacherEvaluation_form.courseInfos?.lecturePpsData?.lastname }} {{ teacherEvaluation_form.courseInfos?.lecturePpsData?.firstname }}</p>-->
<!--            </div>-->
<!--            <div v-else>-->
<!--              <p><strong>Лектор:</strong> {{ teacherEvaluation_form.courseInfos?.lecturePpsData?.lastname }} {{ teacherEvaluation_form.courseInfos?.lecturePpsData?.firstname }}</p>-->
<!--              <p><strong>Семинарист:</strong> {{ teacherEvaluation_form.courseInfos?.seminarPpsData?.lastname }} {{ teacherEvaluation_form.courseInfos?.seminarPpsData?.firstname }}</p>-->
<!--            </div>-->
            <div v-if="teacherEvaluation_form.courseInfos?.lecturePpsData?.id === urlPpsId">
              <p><strong>Лектор:</strong> {{ teacherEvaluation_form.courseInfos?.lecturePpsData?.lastname }} {{ teacherEvaluation_form.courseInfos?.lecturePpsData?.firstname }}</p>
            </div>
            <div v-if="teacherEvaluation_form.courseInfos?.seminarPpsData?.id === urlPpsId">
              <div v-if="teacherEvaluation_form.courseInfos?.lecturePpsData?.id !== teacherEvaluation_form.courseInfos?.seminarPpsData?.id">
                <p><strong>Семинарист:</strong> {{ teacherEvaluation_form.courseInfos?.seminarPpsData?.lastname }} {{ teacherEvaluation_form.courseInfos?.seminarPpsData?.firstname }}</p>
              </div>

            </div>
            <p><strong>Язык обучения:</strong> {{ teacherEvaluation_form.courseInfos?.formationEducationProgram?.educationDiscipline?.language?.native_name}}</p>
            <p><strong>Курс:</strong> {{ teacherEvaluation_form.courseInfos?.educationCurriculum?.study_course }}</p>
            <p><strong>Семестр:</strong> {{ teacherEvaluation_form.courseInfos?.formationEducationProgram?.semester }}</p>
            <p><strong>Кредит:</strong> {{ teacherEvaluation_form.courseInfos?.formationEducationProgram?.credit }}</p>






          </div>

        </div>
      </div>


            <div class="row mt-4 mb-4">
              <ul>
                <li>Блок 1 - Личностные качества ППС</li>
                <li>Блок 2 - Профессиональный уровень ППС</li>
                <li>Блок 3 - Этические требования к ППС</li>
                <li>Блок 4 - Соблюдения норм законодательства</li>
                <li>Блок 5 - Учебная нагрузка обучающегося</li>
              </ul>
            </div>


<!--            <div class="row">-->
<!--              <p>-->
<!--                <b>Цель анкетирования – изучение мнений студентов о профессиональных и некоторых личных качествах преподавателей университета</b>-->
<!--              </p>-->

<!--              <ul>-->
<!--                Просим Вас заполнить анкету, оценив качества каждого из преподавателей по следующей шкале:-->
<!--                <li>5 баллов – качество проявляется практически всегда и наилучшим образом;</li>-->
<!--                <li>4 балла – качество присутствует и проявляется достаточно часто;</li>-->
<!--                <li>3 балла – качество проявляется на уровне «50 на 50»;</li>-->
<!--                <li>2 балла – качество присутствует, но проявляется нечасто;</li>-->
<!--                <li>1 балл – качество проявляется очень и очень редко;</li>-->
<!--                <li>0 баллов – качество практически отсутствует.</li>-->
<!--              </ul>-->

<!--              <p style="color: red">-->
<!--                Пожалуйста, отнеситесь к заполнению анкеты предельно внимательно и ответственно. Будьте объективны, не спешите с выставлением баллов. Заполняйте анкету тщательно и добросовестно, руководствуясь нормами морали, уважая личность преподавателя.-->
<!--              </p>-->
<!--            </div>-->



      <div class="row mt-4 mb-4">
        <div class="col-md-12 mt-4">
          <div class="mt-4" v-for="(teacherEvaluationBlock, teacherEvaluationBlockIndex) in teacherEvaluation_form.teacherEvaluationQuestions" :key="teacherEvaluationBlockIndex">
            <h4>Блок {{teacherEvaluationBlockIndex}}</h4>

            <div class="mt-4" v-for="(teacherEvaluationQuestions, teacherEvaluationQuestionsIndex) in teacherEvaluationBlock" :key="teacherEvaluationQuestionsIndex">

              <b>{{teacherEvaluationQuestions.text}}</b>

              <div class="mt-4" v-for="(answers, answersIndex) in teacherEvaluationQuestions.teacherEvaluationAnswers" :key="answersIndex">

                {{answers.text}} - {{answers?.score}}



                <input class="form-check-input" type="radio" :name="'flexRadioDefault_'+answers.question_id" :id="'flexRadioDefault_'+answers.id"
                       :value="answers.id"
                       :checked="answers.id === teacherEvaluation_form.teacherEvaluateStudentAnswers?.find(i=>i.answer_id === answers.id && i.question_id === answers.question_id)?.answer_id"
                       @change="changeAnswer(answers.question_id, $event)"
                       :disabled="isTeacherEvaluateStudentAnswers">
<!--                :checked="answers.id === teacherEvaluation_form.teacherEvaluateStudentAnswers.find(i=>i.answer_id === answers.id).answer_id"-->
<!-- (teacherEvaluationData?.find(i=>i.answer_id == 50 && i.question_id == answers.question_id)
                || teacherEvaluationData?.find(i=>i.answer_id == 53 && i.question_id == answers.question_id)) && (answers.answer_id == 50 || answers.answer_id == 53)-->

                <div v-if="[50, 53].includes(parseInt((teacherEvaluationData?.find(i=>i.answer_id === answers.id && i.question_id === answers.question_id)?.answer_id)))">
                  <input type="file" class="form-control" @change="handleFileUpload(answers.id, $event)" :disabled="isTeacherEvaluateStudentAnswers">
                </div>

                <div v-if="teacherEvaluation_form.teacherEvaluateStudentAnswers.length != 0">
                  <div v-if="[50, 53].includes(parseInt((teacherEvaluation_form.teacherEvaluateStudentAnswers?.find(i=>i.answer_id === answers.id)?.answer_id)))">
                    <a :href="'https://back.uib.kz/uploads/' + teacherEvaluation_form.teacherEvaluateStudentAnswers?.find(i=>i.answer_id === answers.id)?.file">Файл</a>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="my-3 text-center" v-if="teacherEvaluation_form.teacherEvaluateStudentAnswers.length == 0">
        <Button label="Сохранить" @click="saveTeacherEvaluation"/>
      </div>

      <ConfirmDialog></ConfirmDialog>






<!--      <div class="table-responsive" v-if="teacherEvaluation_form.attestation.length != 0">-->
<!--        <table class="table table-bordered">-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>№</th>-->
<!--            <th>ФИО преподавателя</th>-->
<!--            <th>Название курса</th>-->

<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr v-for="(attestation, attestationIndex) in teacherEvaluation_form.attestation" :key="attestationIndex">-->
<!--            <td>{{attestationIndex+1}}</td>-->
<!--            <td>{{attestation.pps_data_lastname}} {{attestation.pps_data_firstname}}</td>-->
<!--            <td>{{attestation.course_name}}</td>-->


<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->


      <!-- Table Start -->
<!--      <DataTable v-if="teacherEvaluation_form.attestation.length != 0" :value="teacherEvaluation_form.attestation" :paginator="true" :rows="10"-->
<!--                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"-->
<!--                 :rowsPerPageOptions="[10, 20, 50, 100, 200]"-->
<!--                 filterDisplay="menu"-->
<!--                 currentPageReportTemplate="Показано {last} из {totalRecords}"-->
<!--                 showGridlines-->
<!--                 stripedRows responsiveLayout="scroll">-->

<!--        <Column header="Преподаватель">-->
<!--          <template #body="{data}">-->
<!--            {{data.pps_data_lastname}} {{data.pps_data_firstname}}-->
<!--          </template>-->
<!--        </Column>-->

<!--        <Column field="course_name" header="Название курса"></Column>-->




<!--        <Column header="Анкета">-->
<!--          <template #body="{data}">-->
<!--            <Button label="Заполнить анкету" @click="addTeacherEvaluationDialog(data.course_id)"/>-->
<!--          </template>-->
<!--        </Column>-->


<!--      </DataTable>-->
      <!-- Table End -->


<!--      <Dialog v-model:visible="addTeacherEvaluationDisplay" :style="{width: '100%', maxWidth: '800px'}" :modal="true" :closable="false">-->
<!--        <template #header>-->
<!--          <h5>Добавить анкетирование</h5>-->
<!--        </template>-->
<!--        <div class="col-md-12 mt-4">-->
<!--          <div class="mt-4" v-for="(teacherEvaluationBlock, teacherEvaluationBlockIndex) in teacherEvaluation_form.teacherEvaluationQuestions" :key="teacherEvaluationBlockIndex">-->
<!--            <h4>Блок {{teacherEvaluationBlockIndex}}</h4>-->

<!--            <div class="mt-4" v-for="(teacherEvaluationQuestions, teacherEvaluationQuestionsIndex) in teacherEvaluationBlock" :key="teacherEvaluationQuestionsIndex">-->

<!--              <b>{{teacherEvaluationQuestions.text}}</b>-->
<!--              <div class="mt-4" v-for="(answers, answersIndex) in teacherEvaluationQuestions.teacherEvaluationAnswers" :key="answersIndex">-->

<!--                {{answers.text}} - {{answers?.score}}-->
<!--                <input class="form-check-input" type="radio" name="flexRadioDefault" :id="'flexRadioDefault_'+answers.question_id"-->
<!--                       :value="answers.id" @change="changeAnswer($event)">-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->
<!--        <template #footer>-->
<!--          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeTeacherEvaluationDialog"/>-->
<!--        </template>-->
<!--      </Dialog>-->


    </div>
  </div>



</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "TeacherEvaluation",

  data() {
    return {

      urlCourseId: this.$route.query.course_id || 0,
      urlPpsId: this.$route.query.pps_id || 0,
      teacherEvaluationData: [],
      addTeacherEvaluationDisplay: false
    }
  },
  computed: {
    ...mapState('teacherEvaluation', ['teacherEvaluation_form']),
    isTeacherEvaluateStudentAnswers() {
      return this.teacherEvaluation_form.teacherEvaluateStudentAnswers?.length !== 0;
    }

  },

  methods: {
    ...mapActions('teacherEvaluation', ['GET_EDUCATION_COURSE_DATA_BY_ID','GET_STUDENT_ATTESTATION',
      'GET_TEACHER_EVALUATION_QUESTIONS', 'POST_TEACHER_EVALUATE_STUDENT_ANSWERS',
      'GET_TEACHER_EVALUATE_STUDENT_ANSWERS']),

    ...mapMutations('teacherEvaluation', ['SET_TEACHER_EVALUATION_FILE']),

    addTeacherEvaluationDialog(course_id) {
      console.log(course_id, 'course_id')
      this.addTeacherEvaluationDisplay = true
    },
    closeTeacherEvaluationDialog() {
      this.addTeacherEvaluationDisplay = false
    },
    changeAnswer(question_id, e){
      console.log(question_id, 'question_id')
      let answer_id = e.target.value
      console.log(answer_id, 'answer_id')

      if (!this.teacherEvaluationData.find(i=>i.question_id == question_id)){
        this.teacherEvaluationData.push({
          question_id: question_id,
          answer_id: answer_id,
          education_course_id: this.urlCourseId,
          pps_id: this.urlPpsId,
        })
      }
      else {
        this.teacherEvaluationData.find(i=>i.question_id == question_id).answer_id = answer_id
      }
      console.log(this.teacherEvaluationData, 'this.teacherEvaluationData')

    },
    handleFileUpload(id, e) {
      console.log(e.target, 'e target')
      console.log(id, 'id')
      let file = e.target.files[0];
      let answer_id = id

      if (!this.teacherEvaluationData.find(i=>i.answer_id == answer_id)){
        this.teacherEvaluationData.push({
          file: file,
        })
      }
      else {
        this.teacherEvaluationData.find(i=>i.answer_id == answer_id).file = file
      }

      // this.SET_TEACHER_EVALUATION_FILE({'file': file, 'answer_id': answer_id})
      // console.log({
      //   'file': file,
      //   'answer_id': answer_id,
      // }, 'set teacher evaluation file')
    },
    async saveTeacherEvaluation() {
      this.$confirm.require({
        message: 'Вы точно хотите сохранить? После сохранения не будет возможности поменять выбор.',
        header: 'Потверждение',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          let data = await this.POST_TEACHER_EVALUATE_STUDENT_ANSWERS(this.teacherEvaluationData)
          if (data) {
            await this.GET_TEACHER_EVALUATE_STUDENT_ANSWERS({pps_id: this.urlPpsId, course_id: this.urlCourseId})
            this.$message({text: 'Успешно сохранено'})
          }


        },
        reject: () => {
          this.$confirm.close();
        }
      });


    }
  },

  async mounted() {
    await this.GET_EDUCATION_COURSE_DATA_BY_ID(this.urlCourseId)
    await this.GET_TEACHER_EVALUATE_STUDENT_ANSWERS({pps_id: this.urlPpsId, course_id: this.urlCourseId})
    await this.GET_STUDENT_ATTESTATION()
    await this.GET_TEACHER_EVALUATION_QUESTIONS()
  }
}
</script>

<style scoped>

</style>